import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage } from 'react-intl'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import posed, { PoseGroup } from "react-pose";
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';

const PoseRow = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    delay: ({ i }) => i * 25,
  },
  exit: {
    opacity: 0,
    x: ({ selectedItemId, id }) =>
      id === selectedItemId ? 50 : -50
  }
});

class ProgrammePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      listId: 0,
    }
  }

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale
    const title = "Programme"

    const post = data.wordpressPage
    const hasData = post.acf && post.acf.page_programme__programme_days;
    const programmeDays = post.acf.page_programme__programme_days

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-b"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 py-3">
            <br />

            {hasData &&
              programmeDays.map((row, key) => {

                const isActive = (key === this.state.listId);
                const colorClasses = ['yellow-900', 'pink-900', 'blue-900'];
                const colorClass = colorClasses[(key) % 3];
                const arrow = (isActive) ? <IoMdArrowDropdown className={`text-${colorClass}`} style={{ fontSize: `3.5rem` }} /> : <IoMdArrowDropup className={`text-${colorClass}`} style={{ fontSize: `3.5rem` }} />
                const clickableClass = (isActive) ? "block mb-5" : "block mb-5 cursor-pointer";

                return (
                  <a
                    href={`#program-section-${key}`}
                    id={`program-section-${key}`}
                    className={clickableClass}
                    key={key}
                    onClick={() => this.setState({ listId: key })}>

                    <div className="flex">
                      <p className="flex-grow tk-museo text-mmd">
                        <span className="font-semibold text-purple-900">
                          {row.day}
                          {row.title && (
                            <>
                              <span className="text-yellow-900 mx-1">|</span>
                              {row.title}
                            </>
                          )}
                        </span>
                        <br />
                        <span className="font-light text-blue-900">{row.subtitle}</span>
                      </p>
                      <span>{arrow}</span>
                    </div>

                    {(isActive && row.show_notification === true) &&
                      <div className="text-osm text-gray-900"
                        dangerouslySetInnerHTML={{ __html: row.notification }}>
                      </div>
                    }

                    {(isActive) &&
                      <span className={`block border-t-2 border-${colorClass}`}></span>
                    }

                    <PoseGroup>
                      {(isActive) &&
                        programmeDays[this.state.listId].programme.map((programme, key) => {
                          return (
                            <PoseRow key={key} i={key}>{this.renderProgrammeRow(programme, key)}</PoseRow>
                          )
                        })
                      }
                    </PoseGroup>

                    <span className={`block border-t-2 border-${colorClass}`}></span>
                  </a>
                )
              })}
            <br />
            <br />
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }

  renderProgrammeRow(programme, key) {

    const rowStyle = (((key) % 2) === 0) ? { backgroundColor: `#F7FAFD` } : {};

    return (
      <div style={rowStyle} className="sm:flex flex-col">

        <div className="block sm:flex flex-wrap sm:flex-no-wrap flex-row">
          <div className="px-3 py-4 flex-grow-0 font-semibold text-purple-900 text-olg whitespace-no-wrap" style={{ minWidth: '190px' }}>
            {programme.time}
          </div>
          <div className="px-3 py-4 flex-grow text-olg sm-5">
            <span className="block text-purple-900 font-bold">{programme.title}</span>
            {(programme.line_a.length > 0) &&
              <span className="block text-blue-900 font-semibold" dangerouslySetInnerHTML={{ __html: programme.line_a }} />
            }
            {(programme.line_b.length > 0) &&
              <span className="block text-yellow-900 font-semibold">{programme.line_b}</span>
            }
          </div>
          <div className="px-3 py-4 flex-grow-0 text-olg text-gray-900 whitespace-no-wrap">
            {programme.location}
          </div>
        </div>
      </div>
    );
  }
}

export default ProgrammePage

export const query = graphql`
  query($pId: Int!, $pType: String!) {

    wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      id
      title
      acf {
        page_programme__programme_days {
          day
          title
          subtitle
          show_notification
          notification
          programme {
            time
            title
            location
            line_a
            line_b
          }
        }
      }
    }

    headerImage: file(relativePath: { eq: "jesedu2024-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`